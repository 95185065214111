export function registerComponent(componentName, componentObject) {
  if (ko.components.isRegistered(componentName)) return;

  ko.components.register(componentName, {
    viewModel: {
      createViewModel: (params, componentInfo) => {
        try {
          if ("model" in componentObject)
            return new componentObject.model(
              params,
              componentInfo.element,
              componentInfo.templateNodes
            );

          if ("createModel" in componentObject)
            componentObject.createModel(params, componentInfo);
        } catch (e) {
          console.error(e, componentName, componentObject);
          console.trace();
        }
      },
    },
    template: componentObject.template,
  });
}

export function registerComponents(components) {
  Object.entries(components).forEach(([key, value]) =>
    registerComponent(key, value)
  );
}
