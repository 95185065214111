const SPEED = 100;
const MAX_DURATION = 3500;

function increaser(value) {
  const start = Date.now();
  const duration = Math.min(SPEED * value, MAX_DURATION);

  return () => {
    const diff = Date.now() - start;
    if (diff > duration) return value;
    return Math.floor((value * diff) / duration);
  };
}

export function useIncreasingNumber() {
  let value = ko.observable(0);
  const finishValue = ko.observable(0);

  return {
    value,
    finishValue,
    setValue: (newValue) => {
      finishValue(newValue);
      const increase = increaser(newValue);
      const interval = setInterval(() => {
        if (value() === newValue) {
          clearInterval(interval);
        } else {
          value(increase());
        }
      }, 4);
    }
  };
}
