import { useIncreasingNumber } from './use-increasing-number';

function getData() {
  return fetch('/site/stat').then((res) => res.json());
}

export default function () {
  const loading = ko.observable(true);

  const fields = {
    emails: useIncreasingNumber(),
    sms: useIncreasingNumber(),
    polls: useIncreasingNumber(),
    answers: useIncreasingNumber()
  };

  const rating = ko.observable(0);

  const nps = {
    promoters: ko.observable(0),
    critics: ko.observable(0),
    neutrals: ko.observable(0),
    result: ko.observable(0)
  };

  getData().then((data) => {
    if (data) {
      rating(data.rating);
      Object.entries(fields).forEach(([key, value]) => {
        console.log(fields, key, value, data[key]);
        value.setValue(data[key]);
      });
      Object.entries(nps).forEach(([key, value]) => {
        let num = Math.round(data.nps[key] * 10) / 10;
        value(num);
      });
    }

    loading(false);
  });
  return {
    loading,
    ...fields,
    rating,
    nps
  };
}
