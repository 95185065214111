export default function (params) {
  const { promoters, neutrals, critics, result } = params.value;

  let radius = 85;
  let length = 2 * Math.PI * radius;
  let startOffset = length / 4;
  let lineOffset = 2;

  const arcs = ko.computed(() => {
    let promotersLength = (length / 100) * promoters();
    let promotersOffset = startOffset;

    let neutralsLength = (length / 100) * neutrals();
    let neutralsOffset = startOffset - promotersLength;

    let criticsLength = (length / 100) * critics();
    let criticsOffset = neutralsOffset - neutralsLength;

    return {
      promoters: {
        'stroke-dasharray': `${promotersLength - 2 * lineOffset} ${length - promotersLength + 2 * lineOffset}`,
        'stroke-dashoffset': promotersOffset - lineOffset
      },
      neutrals: {
        'stroke-dasharray': `${neutralsLength - 2 * lineOffset} ${length - neutralsLength + 2 * lineOffset}`,
        'stroke-dashoffset': neutralsOffset - lineOffset
      },
      critics: {
        'stroke-dasharray': `${criticsLength - 2 * lineOffset} ${length - criticsLength + 2 * lineOffset}`,
        'stroke-dashoffset': criticsOffset - lineOffset
      }
    };
  });


  return {
    radius,
    arcs,
    promoters,
    neutrals,
    critics,
    result
  };
}
